
#carouselExampleIndicators {
  padding-top: 115px;

  .carousel-inner {
    .web {
      display: block;
      width: 100%;
      @include for-large-mobile {
        display: none;
      }
    }

    .mobile {
      display: none;
      width: 100%;
      @include for-large-mobile {
        display: block;
      }
    }
  }

  @include for-large-mobile {
    padding-top: 90px;
  }

  ol.carousel-indicators {
    position: absolute;
    bottom: 5px;
    margin: 0;
    left: 0;
    right: 0;
    width: auto;
  }

  ol.carousel-indicators li,
  ol.carousel-indicators li.active {
    width: 2vw;
    height: 2vw;
    margin: 0;
    border-radius: 50%;
    border: 0;
    background: transparent;
  }

  ol.carousel-indicators li {
    background: rgba(255, 255, 255, 0.39);
    margin-left: .5rem;
    margin-right: .5rem;
  }

  ol.carousel-indicators li.active {
    background: $company_blue;
  }

}
