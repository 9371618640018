.contactos_section {
  padding-top: 115px;

  .container_title {
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
    @include for-tablet {
      background-size: cover;
    }

    .title {
      text-align: center;
      font-size: 35px;
      margin-top: 70px;
      @include for-large-mobile {
        font-size: 22px;
      }
    }

    p {
      margin: 40px 30px 100px 30px;
      text-align: center;
      font-weight: bold;
      font-size: 26px;

      @include for-large-mobile {
        margin: 40px 20px 60px 20px;
        font-size: 18px;
      }
    }
  }

  #wrapper_contacts {

    @include for-large-mobile {
      margin-top: 60px;
    }

    #container_left_section {
      @include for-large-mobile {
        margin-top: 60px;
      }
    }
  }


  #form_container {
    background: white;
    -webkit-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);

    .wrapper_form_section {
      padding: 60px 70px;
      @include for-tablet {
        padding: 60px 10px;
      }

      @include for-large-mobile {
        padding: 30px 20px;
      }

      .title {
        font-size: 30px;
        color: $company_blue;
        font-weight: 500;

        @include for-large-mobile {
          font-size: 22px;
        }
      }

      .wrapper_form {
        margin-top: 20px;

        .container_input {
          margin-top: 10px;
          position: relative;

          .label {
            display: block;
            position: absolute;
            top: 15px;
            pointer-events: none;
            padding-left: 0.125rem;
            z-index: 1;
            color: #b3b3b3;
            font-size: 1rem;
            font-weight: normal;
            -webkit-transition: all 0.28s ease;
            transition: all 0.28s ease;
          }

          & input:focus ~ .label, & input:valid ~ .label,
          & textarea:focus ~ .label, & textarea:valid ~ .label {
            top: 0;
            left: 0;
            color: $company_yellow;
            font-size: 0.8rem;
          }

        }

        input {
          border: none;
          border-bottom: solid 1px black;
          width: 100%;
          margin-top: 20px;

          &:focus {
            outline-style: none;
          }
        }

        textarea {
          border: none;
          border-bottom: solid 1px black;
          width: 100%;
          margin-top: 20px;

          &:focus {
            outline-style: none;
          }
        }

        button {
          margin-top: 30px;
          border: none;
          background: $company_blue;
          color: white;
          padding: 8px 16px;
          display: block;
          margin-left: auto;

          &.inactive {
            background: #b8b8b8;
            color: $company_blue;
          }
        }

        .error {
          font-size: 12px;
          color: red;

          @include for-large-laptop {
            font-size: 15px;
          }
        }

        .state-info-panel {
          margin: 20px 0;

          .message {
            padding: 10px;
            border-radius: 8px;
            display: none;

            &.success {
              display: block;
              background: palegreen;
              color: green;
            }

            &.error {
              display: block;
              background: #ffc9ca;
              color: red;
            }
          }
        }

      }
    }
  }


  .container_map {
    margin-top: 40px;
    padding: 0;

    iframe {
      width: 100%;
      height: 490px;
    }
  }

}
