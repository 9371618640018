#nav_bar {
  background: white;
  width: 100%;
  height: 150px;
  position: fixed;
  z-index: 30;

  @include for-tablet {
    height: 115px;
  }

  @include for-large-mobile {
    height: 90px;
    &.open_mobil_menu {
      position: initial;
    }
  }

  #container_brand {
    position: absolute;
    display: block;
    width: 20%;
    left: 0;
    height: 100%;

    @include for-large-mobile {
      width: 30%;
      height: 90px;
    }

    #link_brand {
      display: block;
      width: 140px;
      position: absolute;
      right: 0;
      bottom: 0;

      @include for-laptop{
        width: 125px;
      }

      @include for-tablet {
        width: 110px;
      }

      @include for-large-mobile {
        width: 90px;
        top: 20px;
      }

      img {
        width: 140px;

        @include for-laptop{
          width: 125px;
        }

        @include for-tablet {
          width: 110px;
        }

        @include for-large-mobile {
          width: 80px;
        }
      }
    }
  }

  #container_open_mobile_button {
    display: none;
    @include for-large-mobile {
      display: block;
      position: absolute;
      width: 20%;
      right: 0;
      height: 90px;
      > i {
        display: block;
        font-size: 28px;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 30px 0;
      }
    }
  }

  #container_menu {
    position: absolute;
    display: block;
    width: 60%;
    left: 20%;
    height: 67px;
    bottom: 0;
    text-align: center;
    @include for-large-mobile {
      display: none;
      background: white;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 30;
      font-size: 14px;

      &.show {
        display: block;
      }
    }

    .container_close_mobile_menu {
      display: none;
      @include for-large-mobile {
        display: block;
        position: absolute;
        right: 15px;
        top: 10px;
        padding: 12px;
        font-size: 32px;
      }
    }

    #item_menu {
      padding: 0;

      @include for-large-mobile {
        position: absolute;
        padding: 6px;
        width: 80%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      > li {
        position: relative;
        display: inline-block;
        margin-right: -5px;

        @include for-large-mobile {
          width: 100%;
          border: none;
        }

        > a {
          text-decoration: none;
          float: left;
          background-color: white;
          width: 150px;
          height: 65px;
          padding-top: 16px;
          text-align: center;
          color: black;

          @include for-laptop{
            width: 135px;
          }

          @include for-tablet {
            width: 105px;
          }

          @include for-large-mobile {
            float: none;
            display: block;
            width: 100%;
            height: 55px;
            &.with_submenu {
              &::after {
                content: '\22C1';
                color: black;
              }

              &.active {
                color: #ffc90b;

                &::after {
                  color: #ffc90b;
                  content: '\22C0';
                }
              }
            }

          }

          &:hover {
            color: #ffc90b;
          }
        }



        //&:hover {
        //  > a {
        //    color: #ffc90b;
        //    @include for-large-mobile {
        //      color: black;
        //    }
        //  }
        //
        //  .container_submenu {
        //    display: block;
        //    @include for-large-mobile {
        //      display: none;
        //    }
        //  }
        //}

        .container_submenu {
          background: white;
          display: none;
          position: absolute;
          top: 60px;
          width: 150px;
          z-index: 1;
          text-align: left;
          @include for-large-mobile {
            display: none;
            position: initial;
            width: 100%;

            &.show {
              display: block;
            }
          }

          &.active-submenu{
            display: block;
          }

          > ul {
            list-style-type: none;
            padding: 0;

            > li {
              > a {
                background: white;
                font-size: 12px;
                padding: 6px 4px;
                width: 100%;
                display: block;
                color: grey;
                @include for-large-mobile {
                  text-align: center;
                  background: #013355;
                  height: 55px;
                  padding: 20px 0;
                  color: white;
                }

                span {
                  display: inline-block;
                }

                .bullet {
                  visibility: hidden;
                  width: 10%;
                  text-align: center;

                  &.bullet_services {
                    position: relative;
                    top: -10px;
                  }

                  @include for-large-mobile {
                    display: none;
                  }
                }

                .text {
                  width: 85%;
                  @include for-large-mobile {
                    width: 100%;
                  }
                }

                &:hover {
                  text-decoration: none;
                  color: #00268b;

                  .bullet {
                    visibility: visible;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #container_social {
    position: absolute;
    right: 0;
    display: block;
    width: 20%;
    height: 50px;
    top: 70px;

    @include for-laptop{
      top: 60px;
    }

    @include for-tablet {
      top: 35px;
    }

    @include for-large-mobile {
      display: none;
    }

    > ul {
      padding: 0;

      > li {
        position: relative;
        display: inline-block;
        margin-right: -5px;
        width: 60px;
        text-align: center;
        font-size: 32px;

        @include for-laptop{
          font-size: 25px;
        }

        @include for-tablet {
          font-size: 16px;
          width: 45px;
        }

        &:first-child {
          border-right: solid 2px black;
        }

        > a {
          color: black;

          &:hover {
            color: #ffc90b;
          }
        }
      }
    }
  }
}