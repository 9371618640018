.contenedor_nuestro_equipo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 60px;

  margin-top: 60px;

  .wrapper_title {
    display: flex;
    justify-content: center;

    .title {
      font-size: 30px;
      padding: 60px 60px 20px 60px;
      text-align: center;
      background: white;
      display: inline-block;
      @include for-large-mobile {
        font-size: 22px;
      }


      > span:last-child {
        font-weight: bold;
      }
    }
  }

  .container_content {
    margin-top: 35px;

    .container_parent_card {
      display: flex;
      justify-content: flex-end;

      &.second-card {
        justify-content: flex-start;
      }

      .wrapper_card {
        background: $texto_o;
        padding: 40px 0;
        width: 500px;

        .container_card {
          background: white;
          border-top: solid 4px $company_blue;
          width: 280px;
          margin: auto;
          position: relative;

          @include for-large-mobile {
            margin: auto;
            width: 80%;

            &.second-card {
              margin-top: 40px;
            }
          }

          .caption {
            text-align: center;
            height: 70px;
            color: white;
            font-size: 15px;
            font-weight: 500;
            position: absolute;
            bottom: 0;
            background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(45, 45, 94, 0.5021358885351015) 50%, rgba(255, 255, 255, 0) 100%);
            width: 100%;
            padding: 10px;
          }

          span:last-child {
            display: block;
            font-weight: normal;
          }

          img {
            display: block;
            margin: auto;
            width: 100%;
          }
        }
      }
    }

    .container_text {
      background: white;
      padding: 30px;
      color: $company_blue;
      margin-top: 40px;
      text-align: justify;

      @include for-large-mobile {
        margin-top: 0;
      }
    }
  }
}
