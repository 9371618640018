#testimony {
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: cover;

  @include for-large-mobile {
    padding: 60px 35px;
  }

  .wrapper_title {
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      color: $company_yellow;
      @include for-large-mobile{
        font-size: 22px;
      }
    }
  }

  .testimony_section {
    margin-top: 40px;

    .container_title {
      border-left: solid 4px $company_yellow;

      .title {
        color: white;
        font-size: 18px;
        font-weight: bold;

      }
    }

    #container_testimony {
      background: white;
      padding: 30px;

      .carousel-inner {
        .autor {
          margin-top: 30px;

          > span {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}