#info {
  margin-top: 60px;

  #container_text_why {
    .wrapper_text {
      .title {
        color: $company_blue;
        font-size: 30px;
        font-weight: bold;
        text-align: left;
        border-left: solid 4px $company_yellow;

        @include for-large-mobile {
          font-size: 22px;
        }
      }

      > p {
        text-align: justify;
        padding-left: 30px;

        @include for-large-mobile {
          padding: 0 30px;
        }
      }
    }
  }

  #container_vision_and_mission {
    margin-top: 40px;

    p {
      text-align: justify;
      padding-left: 30px;

      @include for-large-mobile {
        padding: 0 30px;
      }
    }

    .title {
      color: $company_blue;
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      border-left: solid 4px $company_blue;
      @include for-large-mobile {
        font-size: 22px;
      }
    }

    .text-vision {
      @include for-large-mobile {
        margin-top: 40px;
      }
    }
  }
}