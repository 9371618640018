#our_team {
  padding: 60px 0;
  background: rgb(219, 219, 219);
  @include for-large-mobile {
    background-size: 100% 38%;
  }

  .wrapper_text {
    .title {
      color: $company_yellow;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      @include for-large-mobile{
        font-size: 22px;
      }
    }
  }

  #people_list {
    margin-top: 40px;

    .container_card {
      background: white;
      border-top: solid 4px $company_blue;
      width: 280px;
      margin: auto;
      position: relative;

      @include for-tablet {
        width: 230px;
      }

      @include for-large-mobile {
        margin: auto;

        &.second-card {
          margin-top: 40px;
        }
      }

      .caption {
        text-align: center;
        height: 70px;
        color: white;
        font-size: 15px;
        font-weight: 500;
        position: absolute;
        bottom: 0;
        background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(45, 45, 94, 0.5021358885351015) 50%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        padding: 10px;
      }

      span:last-child {
        display: block;
        font-weight: normal;
      }

      img {
        display: block;
        margin: auto;
        width: 100%;
      }
    }
  }
}