@mixin for-small-mobile {
  @media (max-width: 320px) { @content; }
}
@mixin for-medium-mobile {
  @media (max-width: 375px) { @content; }
}

@mixin for-large-mobile{
  @media (max-width: 426px) { @content; }
}

@mixin for-tablet{
  @media (max-width: 768px) { @content; }
}

@mixin for-laptop{
  @media (max-width: 1024px) { @content; }
}

@mixin for-large-laptop{
  @media (max-width: 1440px) { @content; }
}

@mixin for-4k-laptop{
  @media (max-width: 2560px) { @content; }
}
