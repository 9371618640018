#footer {
  .schedule {
    padding-top: 70px;
    padding-bottom: 70px;
    background: #dbdbdb;
    color: #06314a;

    @include for-large-mobile {
      padding: 30px 30px;
    }

    .container-message-text {
      padding-top: 24px;
      text-align: right;
    }

    .schedule-title, .message-text {
      font-weight: bold;
    }

    #wrapper_social {
      height: 95px;
      text-align: center;

      @include for-large-mobile {
        height: 70px;
      }

      @include for-tablet {
        height: 165px;
      }

      @include for-laptop {
        height: 120px;
      }

      #container_social {
        color: #06314a;

        > ul {
          padding: 0;

          > li {
            position: relative;
            display: inline-block;
            margin-right: -5px;
            width: 60px;
            text-align: center;
            font-size: 32px;

            @include for-laptop {
              font-size: 25px;
            }

            @include for-tablet {
              font-size: 16px;
              width: 45px;
            }
            @include for-large-mobile {
              font-size: 30px;
              width: 60px;
            }

            &:first-child {
              border-right: solid 2px black;
            }

            > a {
              color: inherit;

              &:hover {
                color: #ffc90b;
              }
            }
          }
        }
      }
    }
  }

  .bottom_footer {
    background: #06314a;
    text-align: center;
    color: white;
    padding: 30px 20px;
    font-weight: bold;

    @include for-large-mobile {
      > span {
        display: block;

        &.second-text {
          padding-top: 8px;
        }
      }
    }
  }
}