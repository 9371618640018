.equipo{
    background-image: url("../image/fondo_nuestro_equipo.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    #title_equipo{
        background-color: white;
        width: 35vw;
        height: 7vw;
        font-size: 3vw;
        font-weight: 480;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $texto;
        margin-bottom: 2.5vw;
    }

    .fondo_col1{
        background-color: $texto_o;
        display: flex;
        justify-content: center;
    }

    .contenedor_integrante{
        margin-top: 6.5vw;
        width: 30vw;
        height: 37vw;
        margin-bottom: 6vw;
        margin-left: 6vw;

        @media only screen and (max-width: 576px) {
            margin-left: 0vw;
        }
    }

    .foto_integrante{
        background-image: url("../image/foto_nuestro_equipo.png");
        background-repeat: no-repeat; 
        background-size: cover;
        height: 100%;
        width: 100%;  
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        
    }

    .line_superior_foto{
        height: .5vw;
        width: 100%;
        background-color: $dorado;
    }
    
    .nombre_posicion_integrante{
        color: white;
        font-size: 2vw;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 1vw;
    }

    .fondo_col2{
        padding-left: 0vw;

        @media only screen and (max-width: 576px) {
            padding-left: 15px;
            display: flex;
            justify-content: center;
        }
    }

    .presentacion_integrante{
        background-color: white;
        font-size: 1.5vw;
        font-weight: 500;
        color: $texto;
        width: 50vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 37vw;
        margin-top: 6.5vw;

        @media only screen and (max-width: 576px) {
            margin-right: 0vw;
            margin-top: 0vw;
        }
    }

    .contenedor_texto_presentacion{
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
    }

    .texto_presentacion{
        width: 42vw;
        margin-bottom: 1vw;
    }

    .fondo_col3{
        padding-right: 0vw;

        @media only screen and (max-width: 576px) {
            padding-right: 15px;
            display: flex;
            justify-content: center;
        }
    }

    .presentacion_integrante2{
        background-color: white;
        position: absolute;
        right: 0;
        font-size: 1.5vw;
        font-weight: 500;
        color: $texto;
        width: 50vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 37vw;
        margin-top: 6.5vw;

        @media only screen and (max-width: 576px) {
            position: relative;
            margin-left: 0vw;
            display: flex;
            justify-content: center;
        }
    }

    .fondo_col4{
        background-color: $texto_o;
        display: flex;
        justify-content: center;
    }

    .contenedor_integrante2{
        margin-top: 6.5vw;
        width: 30vw;
        height: 37vw;
        margin-bottom: 6vw;
        margin-right: 6vw;

        @media only screen and (max-width: 576px) {
            margin-right: 0vw;
        }
    }


    .foto_integrante2{
        background-image: url("../image/foto_nuestro_equipo.png");
        background-repeat: no-repeat; 
        background-size: cover;
        height: 100%;
        width: 100%;  
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
    }
}
