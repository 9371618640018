.contenedor_formulario{
    display: flex;
    flex-direction: column;
    align-items: center;

    .formulario{
        margin-top: 3vw;
    }

    .recuadro{
        margin-top: 1vw;
    }

    .input{
        width: 41vw;
        height: 3vw;
        font-size: 1.5vw;
        font-weight: 500;
        display: flex;
        align-items: flex-start;
    }

    textarea{
        width: 41vw;
        min-height: 10vw;
        font-weight: 500;
        font-size: 1.5vw;
    }

    #boton{
        margin-top: 1vw;
        width: 12vw;
        height: 3.5vw;
        background-color: $boton;
        border: 0;
        margin-left: 29vw;
        font-size: 1.5vw;
        font-weight: 500;
        color: $texto;
        margin-bottom: 5vw;

    }
}
