.vision_and_vision {
  margin-top: 60px;
  @include for-tablet {
    margin-top: 0;
  }

  .container_vision_and_mission {
    text-align: center;

    @include for-tablet {
      padding: 0 30px;
      margin-top: 60px;
    }

    .title {
      font-size: 25px;
      padding-bottom: 10px;

      @include for-large-mobile{
        font-size: 22px;
      }

      > span:last-child {
        font-weight: bold;
      }
    }

    .image_vision_mission {
      padding: 20px 0;
    }
  }
}