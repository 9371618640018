#alliance {
  margin-top: 40px;
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include for-large-mobile {
    background-size: cover;
  }

  .wrapper_text {
    .title {
      color: white;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      @include for-large-mobile{
        font-size: 22px;
      }
    }
  }

  .first_img {
    margin-top: 40px;
    margin-right: 60px;

    @include for-large-mobile {
      margin-right: 0;
    }

    > img {
      float: right;
      width: 210px;

      @include for-large-mobile {
        float: none;
        display: block;
        margin: auto;
      }
    }
  }

  .second_img {
    margin-top: 40px;

    > img {
      float: left;
      width: 210px;
      @include for-large-mobile {
        float: none;
        display: block;
        margin: auto;
      }
    }
  }
}