#we_help_us {
  margin-top: 40px;
  padding: 60px 0 0 0;
  background-repeat: no-repeat;
  background-size: 100% 80%;
  @include for-large-mobile {
    background-size: 100% 38%;
  }

  .wrapper_text {
    .title {
      color: $company_blue;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      @include for-large-mobile{
        font-size: 22px;
      }
    }

    > p:last-child {
      text-align: center;
      font-size: 18px;
    }
  }

  #helps_list {
    margin-top: 40px;

    .container_card {
      background: white;
      padding: 30px;
      border-left: solid 4px $company_yellow;
      -webkit-box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);
      box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);

      &:hover {
        background: $company_yellow;
      }

      a{
        text-decoration: none;
      }

      @include for-tablet {
        padding: 20px;
      }
      @include for-large-mobile {
        width: 65%;
        margin: auto;

        &.second-card {
          margin-top: 40px;
        }
      }

      .title {
        text-align: center;
        height: 100px;
        color: $company_blue;
        font-size: 20px;
        font-weight: 500;
      }

      img {
        display: block;
        margin: auto;
      }
    }
  }
}