.container_who_are {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 150px;
  @include for-tablet {
    padding-top: 115px;
  }

  @include for-large-mobile {
    background-size: 100% 250px;
    background-position-y: 90px;
    padding-top: 90px;
  }

  @include for-small-mobile {
    background-size: 100% 210px;
  }

  #cont_qs {
    background: $texto_o;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    font-weight: 500;
    text-align: center;

    @include for-large-mobile {
      height: 250px;
      font-size: 22px;
    }

    @include for-small-mobile {
      height: 210px;
    }
  }

  #text_container {
    background: rgba(255, 255, 255, 0.85);
    padding: 70px 100px 70px 30px;
    @include for-tablet {
      padding: 50px 55px 50px 30px
    }

    @include for-large-mobile {
      padding: 30px;
    }

    #title_text_qs {
      font-size: 30px;
      color: $company_blue;
      font-weight: bold;

      @include for-large-mobile {
        font-size: 18px;
        text-align: center;
      }
    }

    .cont_text1_qs {
      font-size: 16px;
      text-align: justify;
    }
  }
}


