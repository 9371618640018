.services_section {
  padding-top: 115px;

  .container_title {
    background-size: cover;
    background-repeat: no-repeat;

    .title {
      text-align: center;
      font-size: 30px;
      margin-top: 70px;
      color: $company_blue;

      @include for-large-mobile {
        font-size: 22px;
      }

      .services {
        font-size: 25px;
        @include for-large-mobile {
          font-size: 18px;
        }
      }

      > span {
        display: block;
        font-weight: 500;

        &:first-child {
          font-weight: bold;
        }
      }


    }

    p {
      margin: 40px 30px 60px 30px;
      text-align: center;
      font-weight: bold;

      @include for-large-mobile {
        margin: 40px 20px 60px 20px;
      }
    }
  }

  .container_section_title {
    .wrapper_title {
      text-align: center;
      padding: 20px;

      > span {
        font-weight: bold;
        font-size: 26px;
        color: $company_blue;
        display: inline-block;
        border-left: solid 4px $company_yellow;
        padding-left: 30px;
        @include for-large-mobile {
          font-size: 18px;
        }
      }

      > img {
        height: 72px;
        margin-left: 70px;
        @include for-large-mobile {
          margin-left: 0;
          height: 40px;
        }

        @include for-small-mobile {
          margin-top: 15px;
        }
      }
    }
  }

  .list_services_container {

    .container_left {
      background: linear-gradient(90deg, rgba(186, 186, 186, 1) 0%, rgba(211, 211, 211, 1) 50%, rgba(255, 255, 255, 1) 100%);
      padding: 35px 25px;
      min-height: 440px;

      &.second_section {
        min-height: 270px;
      }

      &.container_white {
        background: white;
      }

      @include for-tablet {
        padding: 35px 15px 35px 0;
      }

      @include for-large-mobile {
        padding: 30px 30px 25px 0px
      }

      .title {
        color: $company_blue;
        font-size: 20px;
        text-align: center;
      }

      .list {
        color: $company_blue;
        margin-top: 20px;
        text-align: justify;
        list-style: '♦ ';
        list-style-position: outside;

        li {
          padding-left: 5px;
        }
      }
    }

    .container_right {
      div {
        height: 100%;

        .container_image {
          @include for-large-mobile {
            height: 230px;
          }
        }
      }

      .container_image {
        background-size: cover;
        background-position: center;
        border-left: solid 4px $company_yellow;

        &.container_line_right {
          border-right: solid 4px $company_yellow;
          border-left: none;

          &.blue_line {
            border-right: solid 4px $company_blue;
            border-left: none;
          }
        }

        &.blue_line {
          border-left: solid 4px $company_blue;
        }
      }
    }
  }

  .fondo_consultoria {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    .titulo_consultoria {
      font-size: 30px;
      font-weight: 700;
      color: white;
      margin-top: 50px;
      margin-bottom: 20px;
      @include for-large-mobile {
        font-size: 22px;
      }
    }

    .texto_consultoria {
      text-align: center;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      color: white;
      margin-bottom: 62px;
      @include for-large-mobile {
        font-size: 16px;
      }
    }
  }
}
