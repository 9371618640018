@charset "UTF-8";
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

#nav_bar {
  background: white;
  width: 100%;
  height: 150px;
  position: fixed;
  z-index: 30;
}

@media (max-width: 768px) {
  #nav_bar {
    height: 115px;
  }
}

@media (max-width: 426px) {
  #nav_bar {
    height: 90px;
  }
  #nav_bar.open_mobil_menu {
    position: initial;
  }
}

#nav_bar #container_brand {
  position: absolute;
  display: block;
  width: 20%;
  left: 0;
  height: 100%;
}

@media (max-width: 426px) {
  #nav_bar #container_brand {
    width: 30%;
    height: 90px;
  }
}

#nav_bar #container_brand #link_brand {
  display: block;
  width: 140px;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1024px) {
  #nav_bar #container_brand #link_brand {
    width: 125px;
  }
}

@media (max-width: 768px) {
  #nav_bar #container_brand #link_brand {
    width: 110px;
  }
}

@media (max-width: 426px) {
  #nav_bar #container_brand #link_brand {
    width: 90px;
    top: 20px;
  }
}

#nav_bar #container_brand #link_brand img {
  width: 140px;
}

@media (max-width: 1024px) {
  #nav_bar #container_brand #link_brand img {
    width: 125px;
  }
}

@media (max-width: 768px) {
  #nav_bar #container_brand #link_brand img {
    width: 110px;
  }
}

@media (max-width: 426px) {
  #nav_bar #container_brand #link_brand img {
    width: 80px;
  }
}

#nav_bar #container_open_mobile_button {
  display: none;
}

@media (max-width: 426px) {
  #nav_bar #container_open_mobile_button {
    display: block;
    position: absolute;
    width: 20%;
    right: 0;
    height: 90px;
  }
  #nav_bar #container_open_mobile_button > i {
    display: block;
    font-size: 28px;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 30px 0;
  }
}

#nav_bar #container_menu {
  position: absolute;
  display: block;
  width: 60%;
  left: 20%;
  height: 67px;
  bottom: 0;
  text-align: center;
}

@media (max-width: 426px) {
  #nav_bar #container_menu {
    display: none;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 30;
    font-size: 14px;
  }
  #nav_bar #container_menu.show {
    display: block;
  }
}

#nav_bar #container_menu .container_close_mobile_menu {
  display: none;
}

@media (max-width: 426px) {
  #nav_bar #container_menu .container_close_mobile_menu {
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 12px;
    font-size: 32px;
  }
}

#nav_bar #container_menu #item_menu {
  padding: 0;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu {
    position: absolute;
    padding: 6px;
    width: 80%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

#nav_bar #container_menu #item_menu > li {
  position: relative;
  display: inline-block;
  margin-right: -5px;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li {
    width: 100%;
    border: none;
  }
}

#nav_bar #container_menu #item_menu > li > a {
  text-decoration: none;
  float: left;
  background-color: white;
  width: 150px;
  height: 65px;
  padding-top: 16px;
  text-align: center;
  color: black;
}

@media (max-width: 1024px) {
  #nav_bar #container_menu #item_menu > li > a {
    width: 135px;
  }
}

@media (max-width: 768px) {
  #nav_bar #container_menu #item_menu > li > a {
    width: 105px;
  }
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li > a {
    float: none;
    display: block;
    width: 100%;
    height: 55px;
  }
  #nav_bar #container_menu #item_menu > li > a.with_submenu::after {
    content: '\22C1';
    color: black;
  }
  #nav_bar #container_menu #item_menu > li > a.with_submenu.active {
    color: #ffc90b;
  }
  #nav_bar #container_menu #item_menu > li > a.with_submenu.active::after {
    color: #ffc90b;
    content: '\22C0';
  }
}

#nav_bar #container_menu #item_menu > li > a:hover {
  color: #ffc90b;
}

#nav_bar #container_menu #item_menu > li .container_submenu {
  background: white;
  display: none;
  position: absolute;
  top: 60px;
  width: 150px;
  z-index: 1;
  text-align: left;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li .container_submenu {
    display: none;
    position: initial;
    width: 100%;
  }
  #nav_bar #container_menu #item_menu > li .container_submenu.show {
    display: block;
  }
}

#nav_bar #container_menu #item_menu > li .container_submenu.active-submenu {
  display: block;
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul {
  list-style-type: none;
  padding: 0;
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a {
  background: white;
  font-size: 12px;
  padding: 6px 4px;
  width: 100%;
  display: block;
  color: grey;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a {
    text-align: center;
    background: #013355;
    height: 55px;
    padding: 20px 0;
    color: white;
  }
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a span {
  display: inline-block;
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a .bullet {
  visibility: hidden;
  width: 10%;
  text-align: center;
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a .bullet.bullet_services {
  position: relative;
  top: -10px;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a .bullet {
    display: none;
  }
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a .text {
  width: 85%;
}

@media (max-width: 426px) {
  #nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a .text {
    width: 100%;
  }
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a:hover {
  text-decoration: none;
  color: #00268b;
}

#nav_bar #container_menu #item_menu > li .container_submenu > ul > li > a:hover .bullet {
  visibility: visible;
}

#nav_bar #container_social {
  position: absolute;
  right: 0;
  display: block;
  width: 20%;
  height: 50px;
  top: 70px;
}

@media (max-width: 1024px) {
  #nav_bar #container_social {
    top: 60px;
  }
}

@media (max-width: 768px) {
  #nav_bar #container_social {
    top: 35px;
  }
}

@media (max-width: 426px) {
  #nav_bar #container_social {
    display: none;
  }
}

#nav_bar #container_social > ul {
  padding: 0;
}

#nav_bar #container_social > ul > li {
  position: relative;
  display: inline-block;
  margin-right: -5px;
  width: 60px;
  text-align: center;
  font-size: 32px;
}

@media (max-width: 1024px) {
  #nav_bar #container_social > ul > li {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  #nav_bar #container_social > ul > li {
    font-size: 16px;
    width: 45px;
  }
}

#nav_bar #container_social > ul > li:first-child {
  border-right: solid 2px black;
}

#nav_bar #container_social > ul > li > a {
  color: black;
}

#nav_bar #container_social > ul > li > a:hover {
  color: #ffc90b;
}

#carouselExampleIndicators {
  padding-top: 115px;
}

#carouselExampleIndicators .carousel-inner .web {
  display: block;
  width: 100%;
}

@media (max-width: 426px) {
  #carouselExampleIndicators .carousel-inner .web {
    display: none;
  }
}

#carouselExampleIndicators .carousel-inner .mobile {
  display: none;
  width: 100%;
}

@media (max-width: 426px) {
  #carouselExampleIndicators .carousel-inner .mobile {
    display: block;
  }
}

@media (max-width: 426px) {
  #carouselExampleIndicators {
    padding-top: 90px;
  }
}

#carouselExampleIndicators ol.carousel-indicators {
  position: absolute;
  bottom: 5px;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
}

#carouselExampleIndicators ol.carousel-indicators li,
#carouselExampleIndicators ol.carousel-indicators li.active {
  width: 2vw;
  height: 2vw;
  margin: 0;
  border-radius: 50%;
  border: 0;
  background: transparent;
}

#carouselExampleIndicators ol.carousel-indicators li {
  background: rgba(255, 255, 255, 0.39);
  margin-left: .5rem;
  margin-right: .5rem;
}

#carouselExampleIndicators ol.carousel-indicators li.active {
  background: #0f2441;
}

#info {
  margin-top: 60px;
}

#info #container_text_why .wrapper_text .title {
  color: #0f2441;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  border-left: solid 4px #e9bc11;
}

@media (max-width: 426px) {
  #info #container_text_why .wrapper_text .title {
    font-size: 22px;
  }
}

#info #container_text_why .wrapper_text > p {
  text-align: justify;
  padding-left: 30px;
}

@media (max-width: 426px) {
  #info #container_text_why .wrapper_text > p {
    padding: 0 30px;
  }
}

#info #container_vision_and_mission {
  margin-top: 40px;
}

#info #container_vision_and_mission p {
  text-align: justify;
  padding-left: 30px;
}

@media (max-width: 426px) {
  #info #container_vision_and_mission p {
    padding: 0 30px;
  }
}

#info #container_vision_and_mission .title {
  color: #0f2441;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  border-left: solid 4px #0f2441;
}

@media (max-width: 426px) {
  #info #container_vision_and_mission .title {
    font-size: 22px;
  }
}

@media (max-width: 426px) {
  #info #container_vision_and_mission .text-vision {
    margin-top: 40px;
  }
}

#we_help_us {
  margin-top: 40px;
  padding: 60px 0 0 0;
  background-repeat: no-repeat;
  background-size: 100% 80%;
}

@media (max-width: 426px) {
  #we_help_us {
    background-size: 100% 38%;
  }
}

#we_help_us .wrapper_text .title {
  color: #0f2441;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 426px) {
  #we_help_us .wrapper_text .title {
    font-size: 22px;
  }
}

#we_help_us .wrapper_text > p:last-child {
  text-align: center;
  font-size: 18px;
}

#we_help_us #helps_list {
  margin-top: 40px;
}

#we_help_us #helps_list .container_card {
  background: white;
  padding: 30px;
  border-left: solid 4px #e9bc11;
  -webkit-box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.75);
}

#we_help_us #helps_list .container_card:hover {
  background: #e9bc11;
}

#we_help_us #helps_list .container_card a {
  text-decoration: none;
}

@media (max-width: 768px) {
  #we_help_us #helps_list .container_card {
    padding: 20px;
  }
}

@media (max-width: 426px) {
  #we_help_us #helps_list .container_card {
    width: 65%;
    margin: auto;
  }
  #we_help_us #helps_list .container_card.second-card {
    margin-top: 40px;
  }
}

#we_help_us #helps_list .container_card .title {
  text-align: center;
  height: 100px;
  color: #0f2441;
  font-size: 20px;
  font-weight: 500;
}

#we_help_us #helps_list .container_card img {
  display: block;
  margin: auto;
}

#alliance {
  margin-top: 40px;
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 426px) {
  #alliance {
    background-size: cover;
  }
}

#alliance .wrapper_text .title {
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 426px) {
  #alliance .wrapper_text .title {
    font-size: 22px;
  }
}

#alliance .first_img {
  margin-top: 40px;
  margin-right: 60px;
}

@media (max-width: 426px) {
  #alliance .first_img {
    margin-right: 0;
  }
}

#alliance .first_img > img {
  float: right;
  width: 210px;
}

@media (max-width: 426px) {
  #alliance .first_img > img {
    float: none;
    display: block;
    margin: auto;
  }
}

#alliance .second_img {
  margin-top: 40px;
}

#alliance .second_img > img {
  float: left;
  width: 210px;
}

@media (max-width: 426px) {
  #alliance .second_img > img {
    float: none;
    display: block;
    margin: auto;
  }
}

#our_team {
  padding: 60px 0;
  background: #dbdbdb;
}

@media (max-width: 426px) {
  #our_team {
    background-size: 100% 38%;
  }
}

#our_team .wrapper_text .title {
  color: #e9bc11;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 426px) {
  #our_team .wrapper_text .title {
    font-size: 22px;
  }
}

#our_team #people_list {
  margin-top: 40px;
}

#our_team #people_list .container_card {
  background: white;
  border-top: solid 4px #0f2441;
  width: 280px;
  margin: auto;
  position: relative;
}

@media (max-width: 768px) {
  #our_team #people_list .container_card {
    width: 230px;
  }
}

@media (max-width: 426px) {
  #our_team #people_list .container_card {
    margin: auto;
  }
  #our_team #people_list .container_card.second-card {
    margin-top: 40px;
  }
}

#our_team #people_list .container_card .caption {
  text-align: center;
  height: 70px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #020024 0%, rgba(45, 45, 94, 0.502136) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  padding: 10px;
}

#our_team #people_list .container_card span:last-child {
  display: block;
  font-weight: normal;
}

#our_team #people_list .container_card img {
  display: block;
  margin: auto;
  width: 100%;
}

#testimony {
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 426px) {
  #testimony {
    padding: 60px 35px;
  }
}

#testimony .wrapper_title .title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: #e9bc11;
}

@media (max-width: 426px) {
  #testimony .wrapper_title .title {
    font-size: 22px;
  }
}

#testimony .testimony_section {
  margin-top: 40px;
}

#testimony .testimony_section .container_title {
  border-left: solid 4px #e9bc11;
}

#testimony .testimony_section .container_title .title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

#testimony .testimony_section #container_testimony {
  background: white;
  padding: 30px;
}

#testimony .testimony_section #container_testimony .carousel-inner .autor {
  margin-top: 30px;
}

#testimony .testimony_section #container_testimony .carousel-inner .autor > span {
  text-transform: uppercase;
}

#wrapper_contacts #container_left_section {
  padding: 60px;
  -webkit-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
  margin-right: 20px;
}

@media (max-width: 768px) {
  #wrapper_contacts #container_left_section {
    padding: 60px 10px;
  }
}

@media (max-width: 426px) {
  #wrapper_contacts #container_left_section {
    padding: 60px 0;
    margin-right: 0;
  }
}

#wrapper_contacts #container_left_section #wrapper_company_title #title {
  display: block;
  font-size: 30px;
  font-weight: 500;
  border-left: solid 4px #e9bc11;
  padding-left: 30px;
}

@media (max-width: 426px) {
  #wrapper_contacts #container_left_section #wrapper_company_title #title {
    padding-left: 20px;
    font-size: 22px;
  }
}

#wrapper_contacts #container_left_section #wrapper_company_title #title a {
  text-decoration: none;
  color: #0f2441;
}

#wrapper_contacts #container_left_section #wrapper_company_title #name_company {
  font-size: 20px;
  font-weight: 500;
  padding-left: 30px;
  display: block;
  margin-top: 20px;
}

@media (max-width: 426px) {
  #wrapper_contacts #container_left_section #wrapper_company_title #name_company {
    padding-left: 20px;
  }
}

#wrapper_contacts #container_left_section .information_container {
  padding-left: 45px;
  margin-top: 40px;
}

@media (max-width: 768px) {
  #wrapper_contacts #container_left_section .information_container {
    padding-left: 15px;
  }
}

@media (max-width: 426px) {
  #wrapper_contacts #container_left_section .information_container {
    padding-left: 25px;
  }
}

#wrapper_contacts #container_left_section .information_container .date {
  margin-top: 8px;
}

#wrapper_contacts #container_left_section .information_container .date .icon {
  width: 25px;
  color: #525051;
}

#wrapper_contacts #container_left_section .information_container .date .icon.multi_linea {
  top: -55px;
  position: relative;
}

#wrapper_contacts #container_left_section .information_container .date .text {
  width: 160px;
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

#wrapper_contacts #container_map {
  padding: 0;
  margin-left: 20px;
}

@media (max-width: 426px) {
  #wrapper_contacts #container_map {
    margin-left: 0;
  }
}

#wrapper_contacts #container_map > iframe {
  height: 100%;
  width: 100%;
}

@media (max-width: 426px) {
  #wrapper_contacts #container_map > iframe {
    height: 320px;
    margin: 40px 0;
  }
}

.atencion {
  background-color: #d8d8d8;
}

.atencion #text1_atencion {
  margin-top: 2vw;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  color: #0f2441;
}

.atencion #text2_atencion {
  text-align: right;
  font-size: 1.5vw;
  font-weight: 500;
  color: #0f2441;
}

.atencion #text3_atencion {
  margin-top: 1vw;
  text-align: right;
  font-size: 1.5vw;
  font-weight: 700;
  color: #0f2441;
  margin-bottom: 3vw;
}

.atencion #social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.atencion .social_atencion {
  display: block;
  margin: auto;
}

.atencion .vl_icon {
  width: .15vw;
  height: 4vw;
  background-color: #0f2441;
  margin-left: 1.8vw;
}

.atencion #icon_email {
  font-size: 2.3vw;
  color: #0f2441;
  padding: 0;
}

.atencion #icon_face {
  margin-left: 1.8vw;
  font-size: 1.5vw;
  color: #0f2441;
  padding: 0;
}

.atencion #icon_what {
  margin-left: 1.8vw;
  font-size: 2vw;
  color: #0f2441;
  padding: 0;
}

#footer .schedule {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #dbdbdb;
  color: #06314a;
}

@media (max-width: 426px) {
  #footer .schedule {
    padding: 30px 30px;
  }
}

#footer .schedule .container-message-text {
  padding-top: 24px;
  text-align: right;
}

#footer .schedule .schedule-title, #footer .schedule .message-text {
  font-weight: bold;
}

#footer .schedule #wrapper_social {
  height: 95px;
  text-align: center;
}

@media (max-width: 426px) {
  #footer .schedule #wrapper_social {
    height: 70px;
  }
}

@media (max-width: 768px) {
  #footer .schedule #wrapper_social {
    height: 165px;
  }
}

@media (max-width: 1024px) {
  #footer .schedule #wrapper_social {
    height: 120px;
  }
}

#footer .schedule #wrapper_social #container_social {
  color: #06314a;
}

#footer .schedule #wrapper_social #container_social > ul {
  padding: 0;
}

#footer .schedule #wrapper_social #container_social > ul > li {
  position: relative;
  display: inline-block;
  margin-right: -5px;
  width: 60px;
  text-align: center;
  font-size: 32px;
}

@media (max-width: 1024px) {
  #footer .schedule #wrapper_social #container_social > ul > li {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  #footer .schedule #wrapper_social #container_social > ul > li {
    font-size: 16px;
    width: 45px;
  }
}

@media (max-width: 426px) {
  #footer .schedule #wrapper_social #container_social > ul > li {
    font-size: 30px;
    width: 60px;
  }
}

#footer .schedule #wrapper_social #container_social > ul > li:first-child {
  border-right: solid 2px black;
}

#footer .schedule #wrapper_social #container_social > ul > li > a {
  color: inherit;
}

#footer .schedule #wrapper_social #container_social > ul > li > a:hover {
  color: #ffc90b;
}

#footer .bottom_footer {
  background: #06314a;
  text-align: center;
  color: white;
  padding: 30px 20px;
  font-weight: bold;
}

@media (max-width: 426px) {
  #footer .bottom_footer > span {
    display: block;
  }
  #footer .bottom_footer > span.second-text {
    padding-top: 8px;
  }
}

.container_who_are {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 150px;
}

@media (max-width: 768px) {
  .container_who_are {
    padding-top: 115px;
  }
}

@media (max-width: 426px) {
  .container_who_are {
    background-size: 100% 250px;
    background-position-y: 90px;
    padding-top: 90px;
  }
}

@media (max-width: 320px) {
  .container_who_are {
    background-size: 100% 210px;
  }
}

.container_who_are #cont_qs {
  background: rgba(70, 97, 113, 0.87);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 426px) {
  .container_who_are #cont_qs {
    height: 250px;
    font-size: 22px;
  }
}

@media (max-width: 320px) {
  .container_who_are #cont_qs {
    height: 210px;
  }
}

.container_who_are #text_container {
  background: rgba(255, 255, 255, 0.85);
  padding: 70px 100px 70px 30px;
}

@media (max-width: 768px) {
  .container_who_are #text_container {
    padding: 50px 55px 50px 30px;
  }
}

@media (max-width: 426px) {
  .container_who_are #text_container {
    padding: 30px;
  }
}

.container_who_are #text_container #title_text_qs {
  font-size: 30px;
  color: #0f2441;
  font-weight: bold;
}

@media (max-width: 426px) {
  .container_who_are #text_container #title_text_qs {
    font-size: 18px;
    text-align: center;
  }
}

.container_who_are #text_container .cont_text1_qs {
  font-size: 16px;
  text-align: justify;
}

.vision_and_vision {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .vision_and_vision {
    margin-top: 0;
  }
}

.vision_and_vision .container_vision_and_mission {
  text-align: center;
}

@media (max-width: 768px) {
  .vision_and_vision .container_vision_and_mission {
    padding: 0 30px;
    margin-top: 60px;
  }
}

.vision_and_vision .container_vision_and_mission .title {
  font-size: 25px;
  padding-bottom: 10px;
}

@media (max-width: 426px) {
  .vision_and_vision .container_vision_and_mission .title {
    font-size: 22px;
  }
}

.vision_and_vision .container_vision_and_mission .title > span:last-child {
  font-weight: bold;
}

.vision_and_vision .container_vision_and_mission .image_vision_mission {
  padding: 20px 0;
}

.equipo {
  background-image: url("../image/fondo_nuestro_equipo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.equipo #title_equipo {
  background-color: white;
  width: 35vw;
  height: 7vw;
  font-size: 3vw;
  font-weight: 480;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f2441;
  margin-bottom: 2.5vw;
}

.equipo .fondo_col1 {
  background-color: rgba(70, 97, 113, 0.87);
  display: flex;
  justify-content: center;
}

.equipo .contenedor_integrante {
  margin-top: 6.5vw;
  width: 30vw;
  height: 37vw;
  margin-bottom: 6vw;
  margin-left: 6vw;
}

@media only screen and (max-width: 576px) {
  .equipo .contenedor_integrante {
    margin-left: 0vw;
  }
}

.equipo .foto_integrante {
  background-image: url("../image/foto_nuestro_equipo.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}

.equipo .line_superior_foto {
  height: .5vw;
  width: 100%;
  background-color: #c7b300;
}

.equipo .nombre_posicion_integrante {
  color: white;
  font-size: 2vw;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1vw;
}

.equipo .fondo_col2 {
  padding-left: 0vw;
}

@media only screen and (max-width: 576px) {
  .equipo .fondo_col2 {
    padding-left: 15px;
    display: flex;
    justify-content: center;
  }
}

.equipo .presentacion_integrante {
  background-color: white;
  font-size: 1.5vw;
  font-weight: 500;
  color: #0f2441;
  width: 50vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 37vw;
  margin-top: 6.5vw;
}

@media only screen and (max-width: 576px) {
  .equipo .presentacion_integrante {
    margin-right: 0vw;
    margin-top: 0vw;
  }
}

.equipo .contenedor_texto_presentacion {
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}

.equipo .texto_presentacion {
  width: 42vw;
  margin-bottom: 1vw;
}

.equipo .fondo_col3 {
  padding-right: 0vw;
}

@media only screen and (max-width: 576px) {
  .equipo .fondo_col3 {
    padding-right: 15px;
    display: flex;
    justify-content: center;
  }
}

.equipo .presentacion_integrante2 {
  background-color: white;
  position: absolute;
  right: 0;
  font-size: 1.5vw;
  font-weight: 500;
  color: #0f2441;
  width: 50vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 37vw;
  margin-top: 6.5vw;
}

@media only screen and (max-width: 576px) {
  .equipo .presentacion_integrante2 {
    position: relative;
    margin-left: 0vw;
    display: flex;
    justify-content: center;
  }
}

.equipo .fondo_col4 {
  background-color: rgba(70, 97, 113, 0.87);
  display: flex;
  justify-content: center;
}

.equipo .contenedor_integrante2 {
  margin-top: 6.5vw;
  width: 30vw;
  height: 37vw;
  margin-bottom: 6vw;
  margin-right: 6vw;
}

@media only screen and (max-width: 576px) {
  .equipo .contenedor_integrante2 {
    margin-right: 0vw;
  }
}

.equipo .foto_integrante2 {
  background-image: url("../image/foto_nuestro_equipo.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
}

.services_section {
  padding-top: 115px;
}

.services_section .container_title {
  background-size: cover;
  background-repeat: no-repeat;
}

.services_section .container_title .title {
  text-align: center;
  font-size: 30px;
  margin-top: 70px;
  color: #0f2441;
}

@media (max-width: 426px) {
  .services_section .container_title .title {
    font-size: 22px;
  }
}

.services_section .container_title .title .services {
  font-size: 25px;
}

@media (max-width: 426px) {
  .services_section .container_title .title .services {
    font-size: 18px;
  }
}

.services_section .container_title .title > span {
  display: block;
  font-weight: 500;
}

.services_section .container_title .title > span:first-child {
  font-weight: bold;
}

.services_section .container_title p {
  margin: 40px 30px 60px 30px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 426px) {
  .services_section .container_title p {
    margin: 40px 20px 60px 20px;
  }
}

.services_section .container_section_title .wrapper_title {
  text-align: center;
  padding: 20px;
}

.services_section .container_section_title .wrapper_title > span {
  font-weight: bold;
  font-size: 26px;
  color: #0f2441;
  display: inline-block;
  border-left: solid 4px #e9bc11;
  padding-left: 30px;
}

@media (max-width: 426px) {
  .services_section .container_section_title .wrapper_title > span {
    font-size: 18px;
  }
}

.services_section .container_section_title .wrapper_title > img {
  height: 72px;
  margin-left: 70px;
}

@media (max-width: 426px) {
  .services_section .container_section_title .wrapper_title > img {
    margin-left: 0;
    height: 40px;
  }
}

@media (max-width: 320px) {
  .services_section .container_section_title .wrapper_title > img {
    margin-top: 15px;
  }
}

.services_section .list_services_container .container_left {
  background: linear-gradient(90deg, #bababa 0%, lightgray 50%, white 100%);
  padding: 35px 25px;
  min-height: 440px;
}

.services_section .list_services_container .container_left.second_section {
  min-height: 270px;
}

.services_section .list_services_container .container_left.container_white {
  background: white;
}

@media (max-width: 768px) {
  .services_section .list_services_container .container_left {
    padding: 35px 15px 35px 0;
  }
}

@media (max-width: 426px) {
  .services_section .list_services_container .container_left {
    padding: 30px 30px 25px 0px;
  }
}

.services_section .list_services_container .container_left .title {
  color: #0f2441;
  font-size: 20px;
  text-align: center;
}

.services_section .list_services_container .container_left .list {
  color: #0f2441;
  margin-top: 20px;
  text-align: justify;
  list-style: '♦ ';
  list-style-position: outside;
}

.services_section .list_services_container .container_left .list li {
  padding-left: 5px;
}

.services_section .list_services_container .container_right div {
  height: 100%;
}

@media (max-width: 426px) {
  .services_section .list_services_container .container_right div .container_image {
    height: 230px;
  }
}

.services_section .list_services_container .container_right .container_image {
  background-size: cover;
  background-position: center;
  border-left: solid 4px #e9bc11;
}

.services_section .list_services_container .container_right .container_image.container_line_right {
  border-right: solid 4px #e9bc11;
  border-left: none;
}

.services_section .list_services_container .container_right .container_image.container_line_right.blue_line {
  border-right: solid 4px #0f2441;
  border-left: none;
}

.services_section .list_services_container .container_right .container_image.blue_line {
  border-left: solid 4px #0f2441;
}

.services_section .fondo_consultoria {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.services_section .fondo_consultoria .titulo_consultoria {
  font-size: 30px;
  font-weight: 700;
  color: white;
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (max-width: 426px) {
  .services_section .fondo_consultoria .titulo_consultoria {
    font-size: 22px;
  }
}

.services_section .fondo_consultoria .texto_consultoria {
  text-align: center;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin-bottom: 62px;
}

@media (max-width: 426px) {
  .services_section .fondo_consultoria .texto_consultoria {
    font-size: 16px;
  }
}

.cabecera_contacto {
  background-image: url("../image/foto_contacto.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cabecera_contacto .pregunta {
  color: white;
  font-size: 4vw;
  font-weight: 700;
  text-align: right;
  margin-right: 5vw;
  margin-top: 2.5vw;
}

.cabecera_contacto .titulo_empresa {
  color: white;
  font-size: 2.5vw;
  font-weight: 700;
  width: 25vw;
  text-align: center;
  margin-right: 5vw;
  margin-bottom: 12vw;
  margin-top: 2vw;
}

.contenedor_formulario {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedor_formulario .formulario {
  margin-top: 3vw;
}

.contenedor_formulario .recuadro {
  margin-top: 1vw;
}

.contenedor_formulario .input {
  width: 41vw;
  height: 3vw;
  font-size: 1.5vw;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
}

.contenedor_formulario textarea {
  width: 41vw;
  min-height: 10vw;
  font-weight: 500;
  font-size: 1.5vw;
}

.contenedor_formulario #boton {
  margin-top: 1vw;
  width: 12vw;
  height: 3.5vw;
  background-color: #ffe600;
  border: 0;
  margin-left: 29vw;
  font-size: 1.5vw;
  font-weight: 500;
  color: #0f2441;
  margin-bottom: 5vw;
}

.contenedor_nuestro_equipo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 60px;
  margin-top: 60px;
}

.contenedor_nuestro_equipo .wrapper_title {
  display: flex;
  justify-content: center;
}

.contenedor_nuestro_equipo .wrapper_title .title {
  font-size: 30px;
  padding: 60px 60px 20px 60px;
  text-align: center;
  background: white;
  display: inline-block;
}

@media (max-width: 426px) {
  .contenedor_nuestro_equipo .wrapper_title .title {
    font-size: 22px;
  }
}

.contenedor_nuestro_equipo .wrapper_title .title > span:last-child {
  font-weight: bold;
}

.contenedor_nuestro_equipo .container_content {
  margin-top: 35px;
}

.contenedor_nuestro_equipo .container_content .container_parent_card {
  display: flex;
  justify-content: flex-end;
}

.contenedor_nuestro_equipo .container_content .container_parent_card.second-card {
  justify-content: flex-start;
}

.contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card {
  background: rgba(70, 97, 113, 0.87);
  padding: 40px 0;
  width: 500px;
}

.contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card {
  background: white;
  border-top: solid 4px #0f2441;
  width: 280px;
  margin: auto;
  position: relative;
}

@media (max-width: 426px) {
  .contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card {
    margin: auto;
    width: 80%;
  }
  .contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card.second-card {
    margin-top: 40px;
  }
}

.contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card .caption {
  text-align: center;
  height: 70px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #020024 0%, rgba(45, 45, 94, 0.502136) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  padding: 10px;
}

.contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card span:last-child {
  display: block;
  font-weight: normal;
}

.contenedor_nuestro_equipo .container_content .container_parent_card .wrapper_card .container_card img {
  display: block;
  margin: auto;
  width: 100%;
}

.contenedor_nuestro_equipo .container_content .container_text {
  background: white;
  padding: 30px;
  color: #0f2441;
  margin-top: 40px;
  text-align: justify;
}

@media (max-width: 426px) {
  .contenedor_nuestro_equipo .container_content .container_text {
    margin-top: 0;
  }
}

.contactos_section {
  padding-top: 115px;
}

.contactos_section .container_title {
  background-size: 100%;
  background-repeat: no-repeat;
  color: white;
}

@media (max-width: 768px) {
  .contactos_section .container_title {
    background-size: cover;
  }
}

.contactos_section .container_title .title {
  text-align: center;
  font-size: 35px;
  margin-top: 70px;
}

@media (max-width: 426px) {
  .contactos_section .container_title .title {
    font-size: 22px;
  }
}

.contactos_section .container_title p {
  margin: 40px 30px 100px 30px;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
}

@media (max-width: 426px) {
  .contactos_section .container_title p {
    margin: 40px 20px 60px 20px;
    font-size: 18px;
  }
}

@media (max-width: 426px) {
  .contactos_section #wrapper_contacts {
    margin-top: 60px;
  }
}

@media (max-width: 426px) {
  .contactos_section #wrapper_contacts #container_left_section {
    margin-top: 60px;
  }
}

.contactos_section #form_container {
  background: white;
  -webkit-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
}

.contactos_section #form_container .wrapper_form_section {
  padding: 60px 70px;
}

@media (max-width: 768px) {
  .contactos_section #form_container .wrapper_form_section {
    padding: 60px 10px;
  }
}

@media (max-width: 426px) {
  .contactos_section #form_container .wrapper_form_section {
    padding: 30px 20px;
  }
}

.contactos_section #form_container .wrapper_form_section .title {
  font-size: 30px;
  color: #0f2441;
  font-weight: 500;
}

@media (max-width: 426px) {
  .contactos_section #form_container .wrapper_form_section .title {
    font-size: 22px;
  }
}

.contactos_section #form_container .wrapper_form_section .wrapper_form {
  margin-top: 20px;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .container_input {
  margin-top: 10px;
  position: relative;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .container_input .label {
  display: block;
  position: absolute;
  top: 15px;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: #b3b3b3;
  font-size: 1rem;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .container_input input:focus ~ .label, .contactos_section #form_container .wrapper_form_section .wrapper_form .container_input input:valid ~ .label,
.contactos_section #form_container .wrapper_form_section .wrapper_form .container_input textarea:focus ~ .label, .contactos_section #form_container .wrapper_form_section .wrapper_form .container_input textarea:valid ~ .label {
  top: 0;
  left: 0;
  color: #e9bc11;
  font-size: 0.8rem;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form input {
  border: none;
  border-bottom: solid 1px black;
  width: 100%;
  margin-top: 20px;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form input:focus {
  outline-style: none;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form textarea {
  border: none;
  border-bottom: solid 1px black;
  width: 100%;
  margin-top: 20px;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form textarea:focus {
  outline-style: none;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form button {
  margin-top: 30px;
  border: none;
  background: #0f2441;
  color: white;
  padding: 8px 16px;
  display: block;
  margin-left: auto;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form button.inactive {
  background: #b8b8b8;
  color: #0f2441;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .error {
  font-size: 12px;
  color: red;
}

@media (max-width: 1440px) {
  .contactos_section #form_container .wrapper_form_section .wrapper_form .error {
    font-size: 15px;
  }
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .state-info-panel {
  margin: 20px 0;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .state-info-panel .message {
  padding: 10px;
  border-radius: 8px;
  display: none;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .state-info-panel .message.success {
  display: block;
  background: palegreen;
  color: green;
}

.contactos_section #form_container .wrapper_form_section .wrapper_form .state-info-panel .message.error {
  display: block;
  background: #ffc9ca;
  color: red;
}

.contactos_section .container_map {
  margin-top: 40px;
  padding: 0;
}

.contactos_section .container_map iframe {
  width: 100%;
  height: 490px;
}
