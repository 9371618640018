$texto: #0f2441;
$smenu: rgba(226, 226, 226, 0.87);
$dorado: rgb(199, 179, 0);
$sombra: rgba(0, 0, 0, 0.3);
$fintegrantes: rgb(216, 216, 216);
$tex-tes: rgb(100, 100, 100);
$texto_o: rgba(70,97,113, 0.87);
$azul_color_gradient: rgb(209, 213, 219);
$boton: rgb(255, 230, 0);


///
$company_blue: #0f2441;
$company_yellow: rgb(233, 188, 17);