#wrapper_contacts {
  #container_left_section {
    padding: 60px;
    -webkit-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 6px 17px 0px rgba(0, 0, 0, 0.75);
    margin-right: 20px;

    @include for-tablet {
      padding: 60px 10px;
    }

    @include for-large-mobile {
      padding: 60px 0;
      margin-right: 0;
    }

    #wrapper_company_title {
      #title {
        display: block;
        font-size: 30px;
        font-weight: 500;
        border-left: solid 4px $company_yellow;
        padding-left: 30px;
        @include for-large-mobile {
          padding-left: 20px;
          font-size: 22px;
        }

        a {
          text-decoration: none;
          color: $company_blue;
        }
      }

      #name_company {
        font-size: 20px;
        font-weight: 500;
        padding-left: 30px;
        display: block;
        margin-top: 20px;
        @include for-large-mobile {
          padding-left: 20px;
        }
      }
    }

    .information_container {
      padding-left: 45px;
      margin-top: 40px;

      @include for-tablet {
        padding-left: 15px;
      }
      @include for-large-mobile {
        padding-left: 25px;
      }


      .date {
        margin-top: 8px;

        .icon {
          width: 25px;
          color: rgb(82, 80, 81);

          &.multi_linea {
            top: -55px;
            position: relative;
          }
        }

        .text {
          width: 160px;
          display: inline-block;
          margin: 0;
          padding: 0;
          font-size: 13px;
        }
      }
    }
  }

  #container_map {
    padding: 0;
    margin-left: 20px;

    @include for-large-mobile {
      margin-left: 0;
    }

    > iframe {
      height: 100%;
      width: 100%;

      @include for-large-mobile {
        height: 320px;
        margin: 40px 0;

      }
    }

  }
}